import './App.css';
import React from 'react';
import { FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';

function App() {
  return (
    <div className="App">
      <header className="header">
        <div className="header-content">
          <img src="https://avatars.githubusercontent.com/u/40591375?v=4" alt="Logo" className="header-logo" />
          <h1>Bienvenidos</h1>
        </div>
      </header>
      <main>
        <div className="container">
          <p className="description">Bienvenido a mi página web familiar. Aquí encontrarás una colección de enlaces a diversas webs útiles. Soy un programador frontend y administrador de sistemas informáticos.</p>
          
          <div className="social-media-container">
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FaInstagram />
            </a>
            <a href="https://www.linkedin.com/in/jorge-enrique-tejero-leyva-7bb265123/" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FaLinkedin />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FaTwitter />
            </a>
          </div>

          <h2>Enlaces relevantes</h2>
          <div className="links-grid">
            <a target='_blank' href='https://portfolio.jtejero8.es' className='button'>Portfolio</a>
            <a target='_blank' href='https://bd.jtejero8.es' className='button'>Bd family</a>
            <a target='_blank' href='https://docs.jtejero8.es' className='button'>Docs</a>
            <a target='_blank' href='https://barr.jtejero8.es' className='button'>BARR</a>
            <a target='_blank' href='https://ale.jtejero8.es' className='button'>Juegos Imposibles</a>
            <a target='_blank' href='https://monedero.jtejero8.es' className='button'>Monedero de Finanzas</a>
            <a target='_blank' href='https://Eds.jtejero8.es' className='button'>Edgar P.A</a>
            <a target='_blank' href='https://neurona.jtejero8.es' className='button'>Neurona</a>
            <a target='_blank' href='https://dhoy.jtejero8.es' className='button'>Dhoy noticias</a>
            <a target='_blank' href='https://JetEar.jtejero8.es' className='button'>JetEar Musica</a>
            <a target='_blank' href='https://pizarra.jtejero8.es' className='button'>Pizarra Familiar</a>

          </div>
        </div>
      </main>
      <footer className="footer">
        &copy; 2024 jtejero8
      </footer>
    </div>
  );
}

export default App;
